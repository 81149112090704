import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { useAccessControl } from "../../app/use-access-control";
import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../../hooks/use-access-token";

export const timelineQueryKey = (centerId: string, inquiryId: string) => ["timeline", centerId, inquiryId] as const;

export const useTimeline = (inquiryId: string) => {
  const { activeCenterId } = useAccessControl();
  const { getToken } = useAccessToken();

  return useQuery({
    queryKey: timelineQueryKey(activeCenterId, inquiryId),
    queryFn: async () => getTimeline(await getToken(), activeCenterId, inquiryId),
    select: formatTimelineEvents
  });
};

const eventTypeMapping = {
  CEC_PHONE: { medium: "Phone", canEdit: false, isCec: true },
  INQ_PHONE: { medium: "Phone", canEdit: true, isCec: false }
} as const satisfies Record<TimelineEventResponse["eventType"], unknown>;

const formatTimelineEvents = (response: { events: TimelineEventResponse[] }) =>
  response.events.map(({ externalId, id, eventType, ...event }) => ({
    id: externalId ?? `${id}`,
    eventMedium: eventTypeMapping[eventType].medium,
    canEdit: eventTypeMapping[eventType].canEdit,
    isCec: eventTypeMapping[eventType].isCec,
    ...event
  }));
export type TimelineEvent = ReturnType<typeof formatTimelineEvents>[number];

const timelineEventResponseSchema = z.object({
  id: z.number(),
  externalId: z.string().nullable(),
  eventType: z.enum(["INQ_PHONE", "CEC_PHONE"]),
  startDateTime: z.string(),
  attendees: z.array(
    z.object({ id: z.number(), firstName: z.string(), lastName: z.string(), displayName: z.string() })
  ),
  staffMembers: z.array(
    z.object({ id: z.string(), firstName: z.string(), lastName: z.string(), displayName: z.string() })
  ),
  notes: z.string()
});
type TimelineEventResponse = z.infer<typeof timelineEventResponseSchema>;

const getTimeline = async (accessToken: string, centerId: string, inquiryId: string) => {
  const response = await callLcosService<unknown>(accessToken, `/api/inquiries/${centerId}/${inquiryId}/timeline`);
  return z.object({ events: z.array(timelineEventResponseSchema) }).parse(response);
};
